/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete': {
    width: 32,
    height: 33,
    viewBox: '0 0 32 33',
    data: '<path pid="0" d="M25.333 24.833h-12.23c-.441 0-.865-.175-1.178-.488L4.08 16.5l7.845-7.845a1.667 1.667 0 011.179-.488h12.229a3 3 0 013 3v10.666a3 3 0 01-3 3zM21.18 19.014l-5.027-5.028M21.181 13.986l-5.028 5.028"/>'
  }
})
