export default {

    STORAGE_AUTH_TOKEN: "auth",

    //in _variables.scss, config.js and plugins/vuetify.js
    WIDTH_SM: 688,
    WIDTH_MD: 1016,
    WIDTH_LG: 1344,
    WIDTH_XL: 1344,

    /*MAPBOX: {
        accessToken: "pk.eyJ1IjoicGxheW5leHQiLCJhIjoiY2wzdWpuMG5sMDB0MDNrcW5lOW9sYWgyYiJ9.FV91iCRxZ83-1B0OB3Ou6A",
        style: "mapbox://styles/playnext/cl3ujogub002z15penxokhxmo",
        //accessToken: "pk.eyJ1IjoicGxheW5leHQiLCJhIjoiY2w0NGdkbWhrMDMzdzNkbzJudGU3aHIyZCJ9.aCdgbN39Uloe8_MjL2y1Xw",
        // /style: "mapbox://styles/playnext/cjqr7cp2i36ip2rqilaiuxmq3",
        //style: "mapbox://styles/playnext/cl9n5pfv9002415mg3lkw5421",
    },*/

    YANDEX_MAP_API_KEY: "e26ae0eb-d93a-4eb4-85d6-699b565c1a24", //sweet
    //YANDEX_MAP_API_KEY_SUGGEST: "27321e3a-9d8e-4b9c-aad1-79651704fcb0", // schastyesweet.com
    YANDEX_MAP_API_KEY_SUGGEST: "9ea883e5-edd1-4fdb-a20d-b7538f0718b1", //sweet-suggest
    DADATA_API_KEY: "2b49ff4134287111c694187cf873717a1a649a06", //sweet-suggest

    SESSION_REFRESH_TIMEOUT: 60000,

    DEBOUNCE_INPUT_TIMEOUT: 800,
    DELIVERY_HOUR_LIMIT: 20,






}